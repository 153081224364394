<template>
  <!-- Rename Form -->
  <DeepAppDialog
    v-if="model"
    v-bind="$attrs"
    v-model="model"
    :title="title"
    :max-width="450"
    :max-height="700"
    card-text-cls="overflow-hidden pa-2 d-flex flex-column"
    @close="onCloseAndReset"
  >
    <!-- Content -->
    <template #content>
      <DevTreeView
        class="overflow-y-auto"
        :json="
          JSON.stringify({
            form,
            formTagsCopy,
            mostUsedTags,
            nodesTags,
            nodesCommonTags,
            nodesWithSomeCommonTags,
            tagsList,
            formTagsCopySearchFiltered,
            tagsListSorted,
            formTagsMap
          })
        "
      />
      <v-form
        v-model="isFormValid"
        ref="formRef"
        :validate-on="isFormValid === undefined ? 'submit' : 'input'"
        style="min-height: 0"
        class="d-flex flex-column"
        @submit.prevent
      >
        <div>
          <v-row :dense="true" align="center" justify="start">
            <v-col cols="12">
              <v-text-field
                v-model="searchField"
                variant="outlined"
                aria-multiline="true"
                single-line
                hide-details
                :placeholder="`${$t('placeholders.search')}`"
                :autofocus="true"
                :clearable="true"
                :loading="deepBoxTagsStore.fetchTagsPending"
                name="field-search-tag"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <div
            v-if="canSeeClearAllButton || form.tags.length > 0"
            class="d-flex pa-1 justify-space-between"
          >
            <v-btn
              v-if="canSeeClearAllButton"
              variant="text"
              @click="onClickClearAll"
            >
              {{ $t('labels.clear_all') }}
            </v-btn>

            <span
              v-if="form.tags.length > 0"
              class="align-self-center mr-2 text-body-2 text-medium-emphasis"
            >
              {{ $t('dialogs.tags.n_selected', form.tags.length) }}
            </span>
          </div>
        </div>
        <v-list
          v-model:selected="form.tags"
          density="compact"
          class="px-1 overflow-y-auto prevent-select"
          select-strategy="classic"
          item-value="key"
        >
          <v-list-item
            v-for="tag in tagsListSorted"
            :key="tag.key"
            :value="tag.key"
            slim
            class="tags-list-item"
            @click="onTagClick(tag)"
          >
            <template #prepend="{ isActive }">
              <v-list-item-action start>
                <v-checkbox-btn
                  color="primary"
                  :indeterminate="isTagKeyInNodesWithSomeCommonTags(tag.key)"
                  :model-value="isActive"
                ></v-checkbox-btn>
              </v-list-item-action>
            </template>
            <template #title>
              <CoreTag :tag="tag" />
            </template>
          </v-list-item>
          <v-row :dense="true" align="center" justify="start">
            <v-col align="center">
              <VBtnSecondary
                v-if="
                  deepBoxTagsStore.tags.size >
                  deepBoxTagsStore.tags.items.length
                "
                name="tags-load-more"
                :loading="deepBoxTagsStore.fetchTagsPending"
                @click="onClickLoadMoreTags"
              >
                {{ $t('labels.load_more') }}
              </VBtnSecondary>
            </v-col>
          </v-row>

          <div v-if="tagsListSorted.length === 0" class="no-data">
            {{ $t('form.noDataText') }}
          </div>
        </v-list>
      </v-form>
    </template>
    <!-- /Content -->
    <!-- Actions -->
    <template #actions>
      <VBtnSecondary
        v-if="!device.isMobile && organizationId && linkManageTags"
        name="link-manage-tags"
        text
        target="_self"
        :href="linkManageTags"
        @click="onCloseAndReset"
      >
        {{ $t('dialogs.manage_tags') }}
      </VBtnSecondary>

      <v-spacer />
      <VBtnOutlined name="btn-cancel" @click="onCloseAndReset">
        {{ $t('dialogs.cancel') }}
      </VBtnOutlined>

      <VBtnPrimary
        :loading="isBtnSaveLoading"
        name="btn-save"
        @click="onUpdateNodeTags"
      >
        {{ $t('dialogs.save') }}
      </VBtnPrimary>
    </template>
    <!-- /Actions -->
  </DeepAppDialog>
  <!-- /Rename Form -->
</template>

<script lang="ts" setup>
import { computed, inject, onUnmounted, type PropType, ref, watch } from 'vue';
import { DeepAppDialog } from '@deepcloud/deep-ui-lib';
import debounce from 'lodash/debounce';

import { useDeepBoxTagsStore } from '@/stores/deepbox/deepboxes/tags';
import { toast } from 'vue-sonner';
import { useI18n } from 'vue-i18n';
import CoreTag from '@/components/core/CoreTag.vue';
import type { Tag } from '@/api/types/deepbox/tag';
import type { Node, NodeTagsUpdate } from '@/api/types/deepbox/node';
import { deepBoxNodesTagsAPI } from '@/api/deepbox/nodes/nodes-tags';
import DevTreeView from '@/components/dev/DevTreeView.vue';
import { useNodeTags } from '@/composables/use-node-tags';
import { useStorage } from '@vueuse/core';
import { DeviceKey } from '@/plugins/device-detector-js.ts';
import { deepBoxDeepBoxesBoxesTagsAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-tags.ts';
import { clone } from '@/utils/helpers/clone.ts';

const props = defineProps({
  organizationId: {
    type: String,
    default: null
  },
  typeId: {
    type: String,
    default: null
  },
  boxId: {
    type: String,
    default: null
  },
  nodes: {
    type: Array as PropType<Node[]>,
    default: () => []
  }
});

const model = defineModel({ type: Boolean, default: false });

const emit = defineEmits(['success']);

const i18n = useI18n();
const device = inject(DeviceKey);

const deepBoxTagsStore = useDeepBoxTagsStore();

const isFormValid = ref<boolean | undefined>(undefined);

interface FormTags {
  tags: string[];
}

const FORM_INITIAL: FormTags = {
  tags: []
};

const form = ref<FormTags>({
  ...FORM_INITIAL
});

const formTagsMap = computed(() => {
  if (!deepBoxTagsStore.tags?.items || form.value.tags.length === 0) return {};
  return deepBoxTagsStore.tags.items
    .filter((a) => form.value.tags.includes(a.key))
    .reduce(
      (runningLookup, node) => ({
        ...runningLookup,
        [node.key]: node
      }),
      {}
    );
});

const defaultTagsLoadLimit = 20;

const searchField = ref('');
const tagsListSorted = ref<Tag[]>([]);

const title = computed(() => {
  let t = i18n.t('dialogs.tags.title');
  t +=
    props.nodes.length > 1
      ? ' (' +
        props.nodes.length +
        ' ' +
        i18n.t('labels.files', props.nodes?.length) +
        ')'
      : '';
  // Tags ( N files )
  return t;
});

function filterTagByNameWithValue(array: Tag[], valueToSearch: string) {
  return array.filter((t: Tag) =>
    t.displayName?.toLowerCase().includes(valueToSearch.toLowerCase())
  );
}

// save the most used tags key in the localstorage
const mostUsedTagsLS = useStorage<string[]>(
  `most_used_tags_${props.typeId}`,
  []
);

// this ref stores the most used tags obj from the mostUsedTagsLS list (keys)
// should be updated everytime the dialog is opened
const currentMostUsedTagsData = ref<Tag[]>([]);

async function updateCurrentMostUsedTagsData() {
  try {
    const res = await deepBoxDeepBoxesBoxesTagsAPI.get(
      props.typeId,
      props.boxId,
      {
        k: mostUsedTagsLS.value.toString()
      }
    );
    // save the most used tags from the API and sort it according to the order on the mostUsedTagsLS
    currentMostUsedTagsData.value = res?.data.tag.sort(
      (a, b) =>
        mostUsedTagsLS.value.indexOf(a.key) -
        mostUsedTagsLS.value.indexOf(b.key)
    );
  } catch (e) {
    console.error(e);
    toast.error(i18n.t('error.error_occurred'));
  }
}

const mostUsedTags = computed(() => {
  let uniqueMostUsed = [
    ...new Set(currentMostUsedTagsData.value.map((tag) => tag))
  ];

  // filter by searchField if not empty
  if (searchField.value && searchField.value.length > 0) {
    uniqueMostUsed = filterTagByNameWithValue(
      uniqueMostUsed,
      searchField.value
    );
  }
  return uniqueMostUsed?.slice(0, 3);
});

function addMostUsedTag(tag: Tag) {
  const tagIdx = mostUsedTags.value.findIndex((t) => t.key === tag.key);
  if (tagIdx === -1) {
    // remove last element if array has already 3 items
    if (mostUsedTagsLS.value.length === 3) {
      mostUsedTagsLS.value.pop();
    }
    // add tag at 1st position
    mostUsedTagsLS.value = [tag.key].concat(mostUsedTagsLS.value);
  } else {
    // move tag to first position
    mostUsedTagsLS.value.sort((x, y) =>
      x == tag.key ? -1 : y == tag.key ? 1 : 0
    );
  }
}

function removeMostUsedTagByKey(tagKey: string) {
  mostUsedTagsLS.value = mostUsedTagsLS.value.filter((key) => key !== tagKey);
}

const nodesTags = computed(() => {
  const tags: Tag[][] = [];

  props.nodes?.forEach((n) => tags.push(n.tags));

  return tags;
});

const nodesCommonTags = computed(() => {
  if (nodesTags.value.length === 0) return [];
  return nodesTags.value?.reduce((n1, n2) => {
    return n1.filter((o) => n2.some((t) => o.key === t.key));
  });
});

function hasSomeNodeTagKey(tagKey: string) {
  // check if tagKey exists on nodeCommonTags
  const hasIdx = nodesCommonTags.value.findIndex((t) => t.key === tagKey);
  if (hasIdx !== -1) return false;
  let exists = false;
  props.nodes?.forEach((node) => {
    const a = node?.tags.some((t) => {
      return t.key === tagKey;
    });
    if (a) {
      exists = true;
    }
  });
  return exists;
}

function getNodesWithSomeCommonTags() {
  if (nodesTags.value.length === 0) return [];
  const nodeObj = {};
  props.nodes?.forEach((node) => {
    tagsListSorted.value.forEach((filteredTag) => {
      if (
        node.tags.findIndex((nTag) => nTag.key === filteredTag.key) !== -1 &&
        nodesCommonTags.value?.findIndex(
          (nCommonTag) => nCommonTag.key === filteredTag.key
        ) === -1
      ) {
        if (Object.keys(nodeObj).includes(filteredTag.key)) {
          nodeObj[filteredTag.key].push(node.nodeId);
        } else {
          nodeObj[filteredTag.key] = [node.nodeId];
        }
      }
    });
  });

  return nodeObj;
}

const formTagsCopy = ref([]);
watch(
  () => form.value.tags,
  (newValue, oldValue) => {
    // be sure that the formTags copy is updated
    formTagsCopy.value = [];
    newValue.forEach((tagKey) => {
      if (formTagsMap.value) {
        if (Object.keys(formTagsMap.value).includes(tagKey)) {
          formTagsCopy.value.push(formTagsMap.value[tagKey]);
        }
      }
    });
    // formTagsCopy.value = clone(newValue));
    if (oldValue) {
      // remove unselected tags from nodesWithSomeCommonTags
      const currentFormTagsKeys = newValue.map((tagKey) => tagKey);

      const keysRemoved = oldValue
        .map((tagKey) => tagKey)
        .filter((tagKey) => !currentFormTagsKeys.includes(tagKey));
      keysRemoved.forEach((tagKey) => {
        removeTagKeyFromNodesWithSomeCommonTags(tagKey);
      });
    }
  },
  { deep: true }
);

const formTagsCopySearchFiltered = computed(() => {
  if (searchField.value && searchField.value.length > 0) {
    return filterTagByNameWithValue(formTagsCopy.value, searchField.value);
  }
  const tagsCopy = [];
  formTagsCopy.value.forEach((a) => {
    const idx = deepBoxTagsStore.tags.items.findIndex((b) => b.key === a);
    if (idx !== -1) {
      tagsCopy.push(deepBoxTagsStore.tags.items[idx]);
    }
  });
  return tagsCopy;
});

const tagsList = computed(() => {
  const apiTags = deepBoxTagsStore.tags.items.filter(
    (tag) =>
      !mostUsedTags.value.find((mostUsedTag) => tag.key === mostUsedTag.key)
  );

  const tags = [
    ...formTagsCopySearchFiltered.value,
    ...mostUsedTags.value,
    ...apiTags
  ];

  // remove duplicates
  return tags.filter(
    (value, index, array) =>
      array.findIndex((v) => v.key === value.key) === index
  );
});

function getTagsSorted(tags: Tag[]) {
  // common tags on top
  const sortedId = tags.reduce((acc: Tag[], element: Tag) => {
    if (hasSomeNodeTagKey(element.key)) {
      return [element, ...acc];
    }
    return [...acc, element];
  }, []);
  // selected tags on top over common tags
  return sortedId.reduce((acc: Tag[], element: Tag) => {
    const formTagsIdx = form.value.tags.findIndex(
      (tagKey) => tagKey === element.key
    );
    if (formTagsIdx !== -1) {
      return [element, ...acc];
    }
    return [...acc, element];
  }, []);
}

const linkManageTags = computed(() => {
  if (!props.organizationId) return undefined;
  return `${
    import.meta.env.VITE_DEEPADMIN_FRONTEND_BASE_URL
  }organizations/${props.organizationId}/tags`;
});

const nodesWithSomeCommonTags = ref<Record<string, string[]>>({});

function removeTagKeyFromNodesWithSomeCommonTags(tagKey: string) {
  if (Object.keys(nodesWithSomeCommonTags.value).includes(tagKey)) {
    delete nodesWithSomeCommonTags.value[tagKey];
  }
}

function isTagKeyInNodesWithSomeCommonTags(tagKey: string) {
  return (
    Object.keys(nodesWithSomeCommonTags.value).includes(tagKey) &&
    !form.value.tags.includes(tagKey)
  );
}

function setFormTagsFromNodes() {
  if (props.nodes?.length === 1) {
    if (props.nodes[0]?.tags) {
      form.value.tags = [...props.nodes[0].tags.map((a) => a.key)];
    }
  } else if (props.nodes?.length > 1) {
    form.value.tags = nodesCommonTags.value.map((a) => a.key);
  } else {
    form.value.tags = [];
  }
}

watch(
  () => model.value,
  (newValue, oldValue) => {
    if (newValue) {
      fetchInitialTags();
      setFormTagsFromNodes();
      if (mostUsedTagsLS.value) {
        // -------------------------------------------------------------------------------------------------------------
        // PLEASE READ!
        // This is a converter, which converts the values saved in the LS
        // form array with objects to array with strings (tag key)
        // this is need to support the "old" behaviour, which was changed with https://jira.abacus.ch/browse/DEEPBOX-5328
        const mostUsedTagsLSContainsObjects = mostUsedTagsLS.value.some(
          (a) => typeof a === 'object'
        );
        if (mostUsedTagsLSContainsObjects) {
          mostUsedTagsLS.value = mostUsedTagsLS.value.map((a) => a.key);
        }
        // -------------------------------------------------------------------------------------------------------------

        updateCurrentMostUsedTagsData();
      }
    }
    if (oldValue && !newValue) {
      const deepBoxTagsStore = useDeepBoxTagsStore();
      deepBoxTagsStore.$reset();
    }
  },
  { immediate: true }
);

async function resetSearch() {
  deepBoxTagsStore.$reset();
  searchTagsDebounced.cancel();
  await onFetchTags({
    limit: defaultTagsLoadLimit
  });
  tagsListSorted.value = getTagsSorted(tagsList.value);
}

watch(
  () => searchField.value,
  (newValue) => {
    // be sure that the formTags copy is updated
    formTagsCopy.value = clone(form.value.tags);
    if (!newValue) {
      resetSearch();
      return;
    }
    searchTagsDebounced(newValue);
  }
);

onUnmounted(() => {
  searchTagsDebounced.cancel();
});

async function fetchInitialTags() {
  await onFetchTags({
    limit: defaultTagsLoadLimit
  });
  tagsListSorted.value = getTagsSorted(tagsList.value);
  // set nodes witch some common tags
  nodesWithSomeCommonTags.value = getNodesWithSomeCommonTags();
}

function maybeRemoveOldMostUsedTagsFromStorage(
  tagsKeys: string[],
  tagsCurrentKeys: string[]
) {
  tagsKeys.forEach((tagKey) => {
    if (!tagsCurrentKeys.includes(tagKey)) {
      removeMostUsedTagByKey(tagKey);
    }
  });
}

function onTagClick(tag: Tag) {
  const tagIdx = form.value.tags.findIndex((tagKey) => tagKey === tag.key);
  if (tagIdx === -1) {
    addMostUsedTag(tag);
  } else {
    // REMOVE TAG FROM `nodesWithSomeCommonTags`
    removeTagKeyFromNodesWithSomeCommonTags(tag.key);
  }
}

const isBtnSaveLoading = ref(false);

async function onUpdateNodeTags() {
  // iterate over all selected nodes set the tags to add or remove
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promises: any[] = [];
  for (const nodeIdx in props.nodes) {
    const node = props.nodes[nodeIdx];
    const body: NodeTagsUpdate = {
      tagsAdd: [],
      tagsRemove: []
    };

    body.tagsAdd = Object.values(formTagsMap.value).map((t) => t.key);

    node.tags.forEach((nodeTag) => {
      const formTagIdx = form.value.tags.findIndex(
        (tagKey) => tagKey === nodeTag.key
      );

      const nodesWithSomeCommonTagsByTagKey =
        nodesWithSomeCommonTags.value?.[nodeTag.key] || [];

      if (formTagIdx === -1) {
        if (
          nodesWithSomeCommonTagsByTagKey.length === 0 ||
          !nodesWithSomeCommonTagsByTagKey.includes(node.nodeId)
        ) {
          // add tag to `tagsRemove` because is unchecked
          body.tagsRemove?.push(nodeTag.key);
        }
      } else {
        const currentFormTagKey = form.value.tags[formTagIdx];
        body.tagsAdd = body.tagsAdd?.filter((n) => n.key !== currentFormTagKey);
      }
    });
    if (
      (body.tagsAdd && body.tagsAdd.length > 0) ||
      (body.tagsRemove && body.tagsRemove.length > 0)
    ) {
      promises.push(
        deepBoxNodesTagsAPI.updateById(node.nodeId, body, {
          nodeId: node.nodeId,
          tagsAddKeys: body.tagsAdd
        })
      );
    }
  }
  if (promises.length > 0) {
    try {
      isBtnSaveLoading.value = true;
      const responses = await Promise.all(promises);
      responses.forEach(({ data, config }) => {
        const { nodeId, tagsAddKeys } = config;
        const { updateNodeTags } = useNodeTags();
        updateNodeTags({
          nodeId,
          tags: data
        });
        const tagsCurrentKeys = data.map((t) => t.key);
        maybeRemoveOldMostUsedTagsFromStorage(tagsAddKeys, tagsCurrentKeys);
      });
      toast.success(
        i18n.t('dialogs.tags.toast.success.n_updated', props.nodes?.length)
      );
      onCloseAndReset();
      emit('success');
    } catch (e) {
      console.error(e);
      toast.error(i18n.t('error.error_occurred'));
    } finally {
      isBtnSaveLoading.value = false;
    }
  } else {
    onCloseAndReset();
  }
}

function onCloseAndReset() {
  model.value = false;
  setTimeout(() => {
    searchField.value = '';
    isFormValid.value = undefined;
    form.value = { ...FORM_INITIAL };
    tagsListSorted.value = [];
  }, 300);
}

function onClickClearAll() {
  form.value.tags = [];
  nodesWithSomeCommonTags.value = {};
}

async function onFetchTags(params) {
  try {
    return await deepBoxTagsStore.fetchTags({
      typeId: props.typeId,
      boxId: props.boxId,
      params
    });
  } catch (e) {
    toast.error(i18n.t('error.error_occurred'));
  }
}

async function loadMoreTags() {
  const params = {
    limit: defaultTagsLoadLimit,
    offset: deepBoxTagsStore.tags.items.length,
    q: searchField.value
  };
  // Fetch Tags and append to the list
  const res = await onFetchTags(params);
  if (res?.data.tag) {
    tagsListSorted.value = tagsListSorted.value.concat(res.data.tag);
  }
}

const searchTagsDebounced = debounce(async function (search) {
  const params = {
    limit: defaultTagsLoadLimit,
    offset: 0,
    q: search
  };
  await onFetchTags(params);
  tagsListSorted.value = getTagsSorted(tagsList.value);
}, 500);

function onClickLoadMoreTags() {
  if (
    !deepBoxTagsStore.fetchTagsPending &&
    deepBoxTagsStore.tags.items.length < deepBoxTagsStore.tags.size
  ) {
    loadMoreTags();
  }
}

const canSeeClearAllButton = computed(
  () =>
    form.value?.tags.length > 0 ||
    Object.keys(nodesWithSomeCommonTags.value).length > 0
);
</script>
<style scoped lang="scss">
.tag-text {
  text-align: right;
  pointer-events: all;
  color: rgb(73, 80, 87);
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  vertical-align: baseline;
}

.manage-tags-link {
  padding-top: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.show-more {
  padding-left: 16px;

  &:hover {
    text-decoration: underline;
  }
}

.no-data {
  padding-left: 16px;
}

.tags-list-item {
  :deep(.v-list-item__overlay) {
    background-color: transparent;
  }
}
</style>
