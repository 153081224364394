<template>
  <v-menu
    v-if="
      deepBoxDeepBoxesBoxesFavoritesStore.favorites.length > 0 &&
      $vuetify.display.smAndUp
    "
    v-model="favoritesMenu"
    location="bottom"
    :close-on-content-click="false"
  >
    <template #activator="{ props: ActivatorProps }">
      <v-btn
        v-bind="ActivatorProps"
        :class="$vuetify.display.mdAndUp ? 'pa-1 px-2' : 'pa-0'"
      >
        <div
          v-if="$vuetify.display.mdAndUp"
          class="fav-recent-menu-btn"
          data-test-id="favorite-boxes"
        >
          {{ $t('main_view.favorites') }}
        </div>
        <v-icon v-else>fas fa-star</v-icon>
        <v-icon :class="device.isTablet ? 'ml-0' : 'ml-1'">
          {{ favoritesMenu ? 'far fa-angle-up' : 'far fa-angle-down' }}
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="item in deepBoxDeepBoxesBoxesFavoritesStore.favorites"
        :key="item.boxNodeId"
        class="pr-2 pl-4"
      >
        <div style="width: 275px">
          <BoxItemLink
            :box="item"
            :is-shared="boxHelper.isShared(item)"
            is-favourite
            show-favorite
            :data-test-id="`favorite-${item.boxName}`"
            @click:box-item="selectBox(item)"
          />
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { inject, ref } from 'vue';
import BoxItemLink from '@/components/box/BoxItemLink.vue';

import { useDeepBoxDeepBoxesBoxesFavoritesStore } from '@/stores/deepbox/deepboxes/boxes/favorites';
import { useBoxHelper } from '@/components/appbar/use-box-helper';
import type { BoxEntry } from '@/api/types/deepbox/box';
import { DeviceKey } from '@/plugins/device-detector-js.ts';

const device = inject(DeviceKey);

const deepBoxDeepBoxesBoxesFavoritesStore =
  useDeepBoxDeepBoxesBoxesFavoritesStore();

const favoritesMenu = ref(false);

const boxHelper = useBoxHelper();

function selectBox(box: BoxEntry) {
  boxHelper.selectBox(box);
  favoritesMenu.value = false;
}
</script>

<style scoped lang="scss">
.v-menu__content {
  border-radius: 12px !important;
}
</style>
