<template>
  <v-layout>
    <!-- DESKTOP APPBAR -->
    <TheAppBar :is-mobile="device.isMobile" hide-logo>
      <template v-if="deepBoxPreviewStore.currentNodeData" #desktop-prepend-end>
        <v-btn :to="originRoute.to" variant="text">
          <v-icon start>fas fa-left-long</v-icon>
          {{ originRoute.text }}
        </v-btn>
      </template>

      <template v-if="deepBoxPreviewStore.currentNodeData" #desktop-center>
        <template
          v-if="
            nodeIcon &&
            deepBoxPreviewStore.currentNodeData &&
            deepBoxPreviewStore.currentNodeData.fileName
          "
        >
          <img
            v-if="nodeIcon"
            :src="`/svg/files/${nodeIcon}-20-px.svg`"
            class="mr-2"
            alt="icon"
          />
          <span class="text-truncate">
            {{ truncate(deepBoxPreviewStore.currentNodeData.fileName, 30) }}
          </span>
        </template>
      </template>

      <template #desktop-append>
        <div
          v-if="currentFileIndex >= 0"
          class="d-flex flex-row align-center ga-2"
        >
          <div>
            <v-btn
              v-if="currentFileIndex >= 1"
              :to="previousItemRoute"
              icon="fas fa-chevron-left"
              variant="flat"
              density="compact"
            ></v-btn>
          </div>
          <div v-if="parentChildrenFiles.length > 0">
            {{
              $t('navigation.of', {
                currentFile: currentFileIndex + 1,
                filesNumber: parentChildrenFiles.length
              })
            }}
          </div>
          <div v-if="nextItemRoute">
            <v-btn
              v-if="currentFileIndex < parentChildrenFiles.length - 1"
              :to="nextItemRoute"
              icon="fas fa-chevron-right"
              variant="flat"
              density="compact"
            ></v-btn>
          </div>
        </div>
        <span></span>
      </template>
      <!-- /DESKTOP APPBAR -->

      <!-- MOBILE APPBAR -->
      <template #mobile-content>
        <v-btn icon="fas fa-left-long" :to="originRoute.to"></v-btn>

        <div
          v-if="
            nodeIcon &&
            deepBoxPreviewStore.currentNodeData &&
            deepBoxPreviewStore.currentNodeData.fileName
          "
          class="text-body-2 text-truncate"
        >
          <v-icon start>
            <img
              v-if="nodeIcon"
              :src="`/svg/files/${nodeIcon}-20-px.svg`"
              alt="icon"
            />
          </v-icon>
          {{ truncate(deepBoxPreviewStore.currentNodeData.fileName, 30) }}
        </div>
      </template>
    </TheAppBar>
    <!-- /MOBILE APPBAR -->
    <!-- /APP BAR -->

    <!-- DETAILS DRAWER -->
    <BoxDetailDrawer
      v-if="
        isAuthenticated &&
        deepBoxPreviewStore.currentNodeData &&
        deepBoxPreviewStore.currentNodeData.deepBoxNodeId &&
        deepBoxPreviewStore.currentNodeData.boxNodeId
      "
      hide-preview
      :organization-id="deepAdminUsersMeStore.selectedOrganizationId"
      :type-id="deepBoxPreviewStore.currentNodeData.deepBoxNodeId"
      :box-id="deepBoxPreviewStore.currentNodeData.boxNodeId"
    />
    <!-- /DETAILS DRAWER -->

    <v-main>
      <PreviewBtnFabActions
        v-if="
          isAuthenticated &&
          deepBoxPreviewStore.currentNodeData &&
          canShowPreviewBtnFabActions
        "
        :node="deepBoxPreviewStore.currentNodeData.node"
      />
      <router-view :key="$route.fullPath" />
      <!-- Overused subscription blocking overlay -->
      <OverlayOverusedSubscription />
      <!-- /Overused subscription blocking overlay -->
    </v-main>
  </v-layout>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, onUnmounted, watch } from 'vue';

// components
import BoxDetailDrawer from '@/components/box/BoxDetailDrawer.vue';
import OverlayOverusedSubscription from '@/components/overlays/OverlayOverusedSubscription.vue';
import PreviewBtnFabActions from '@/components/preview/PreviewBtnFabActions.vue';
import TheAppBar from '@/components/appbar/TheAppBar.vue';

// stores
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me.ts';
import { useDeepBoxPreviewStore } from '@/stores/deepbox/deepboxes/preview';
import { useGlobalStore } from '@/stores/global/global';

// composables
import { useDisplay } from 'vuetify';
import { useHead } from '@unhead/vue';
import { usePreviewNavigation } from '@/composables/use-preview-navigation.ts';
import { usePreviewView } from '@/composables/use-preview-view';
import { isAuthenticated } from '@/composables/auth/use-keycloak.ts';

// types & constants
import { DeviceKey } from '@/plugins/device-detector-js.ts';
import { constants } from '@/constants';

// utilities
import helpers from '@/utils/helpers.ts';
import last from 'lodash/last';
import { truncate } from '@/utils/deep/';
import { getNodeIcon } from '@/utils/helpers/nodes.ts';

const device = inject(DeviceKey);
const display = useDisplay();

const deepBoxPreviewStore = useDeepBoxPreviewStore();
const deepAdminUsersMeStore = useDeepAdminUsersMeStore();

const globalStore = useGlobalStore();

onUnmounted(() => {
  deepBoxPreviewStore.$reset();
  deepBoxPreviewStore.setBackRoute(undefined);
  globalStore.setBoxDetailsDrawer(false);
});

const { isNodePreview, originRoute } = usePreviewView();

useHead(() => {
  let title = `${constants.DEFAULT_APP_TITLE}`;
  if (deepBoxPreviewStore.currentNodeData?.node?.displayName) {
    title = `${deepBoxPreviewStore.currentNodeData?.node?.displayName} - ${constants.DEFAULT_APP_TITLE}`;
  }

  return {
    title
  };
});

const parentSection = computed(() =>
  helpers.getSection(deepBoxPreviewStore.currentNodeData?.path)
);

const {
  parentChildrenFiles,
  currentFileIndex,
  nextItemRoute,
  previousItemRoute
} = usePreviewNavigation();

const nodeIcon = computed(() =>
  getNodeIcon({
    type: deepBoxPreviewStore.currentNodeData?.nodeType,
    mimeType: deepBoxPreviewStore.currentNodeData?.fileMimeType
  })
);

const canShowPreviewBtnFabActions = computed(() => {
  if (
    !deepBoxPreviewStore.currentNodeData ||
    !deepBoxPreviewStore.currentNodeData.node
  )
    return false;

  return Object.keys(deepBoxPreviewStore.currentNodeData.node).length !== 0;
});

watch(
  () => isNodePreview.value,
  (newValue) => {
    if (newValue) {
      deepBoxPreviewStore.fetchParentNodeChildren({
        section: parentSection.value,
        typeId: deepBoxPreviewStore.currentNodeData?.deepBoxNodeId,
        boxId: deepBoxPreviewStore.currentNodeData?.boxNodeId,
        nodeId: last(deepBoxPreviewStore.currentNodeData?.path?.segments).nodeId
      });
    }
  }
);

watch(
  () => display.mobile.value,
  (newValue) => {
    if (canShowPreviewBtnFabActions.value) {
      globalStore.setBoxDetailsDrawer(!newValue);
    }
  }
);

watch(
  () => deepBoxPreviewStore.currentNodeData,
  (newValue) => {
    if (newValue) {
      globalStore.setBoxDetailsBreadCrumbs({
        segments: newValue?.path?.segments || [
          { name: 'files', nodeId: 'files' }
        ],
        typeId: newValue?.deepBoxNodeId,
        boxId: newValue?.boxNodeId
      });
    }
  }
);

onMounted(() => {
  if (!display.mobile.value) {
    globalStore.setBoxDetailsDrawer(true);
  }
});
</script>
