<template>
  <v-layout>
    <!-- APP BAR -->
    <TheAppBar
      :is-mobile="device.isMobile"
      :hide-logo="display.smAndDown.value && isDashboardRoute"
    >
      <template #desktop-prepend-end>
        <MenuOrganizationSelection
          v-if="isAuthenticated && isDashboardRoute"
          :organizations="deepAdminUsersMeStore.getUserSelectableOrganizations"
          :selected-organization="deepAdminUsersMeStore.selectedOrganization"
          :is-menu-disabled="
            deepAdminUsersMeStore.getUserSelectableOrganizations.length <= 1
          "
          :hide-menu-icon="
            deepAdminUsersMeStore.getUserSelectableOrganizations.length <= 1
          "
          @change:organization="onChangeOrganization"
        />
      </template>
      <template #desktop-append>
        <template v-if="isAuthenticated">
          <AppBarFavoritesMenu />
          <AppBarRecentMenu />
          <AppBarServiceMenu
            v-if="selectedOrganizationId"
            :current-organization="deepAdminUsersMeStore.selectedOrganization"
          />
        </template>
        <span></span>
      </template>

      <!-- MOBILE -->
      <template v-if="globalStore.mobileSearch" #mobile-prepend>
        <v-btn
          v-if="globalStore.mobileSearch"
          icon="fas fa-arrow-left"
          @click.prevent.stop="goBack"
        >
        </v-btn>
      </template>
      <template #mobile-content>
        <MenuOrganizationSelection
          v-if="!globalStore.mobileSearch && isDashboardRoute"
          :organizations="deepAdminUsersMeStore.getUserSelectableOrganizations"
          :selected-organization="deepAdminUsersMeStore.selectedOrganization"
          :is-menu-disabled="
            deepAdminUsersMeStore.getUserSelectableOrganizations.length <= 1
          "
          :hide-menu-icon="
            deepAdminUsersMeStore.getUserSelectableOrganizations.length <= 1
          "
          @change:organization="onChangeOrganization"
        />
        <v-text-field
          v-else-if="isDashboardRoute"
          v-model="searchField"
          :placeholder="$t('placeholders.search_box')"
          autofocus
          clearable
          hide-details
          width="100%"
          density="compact"
          name="field-search"
        />
      </template>
      <template v-if="isDashboardRoute" #mobile-append>
        <v-btn
          v-if="isAuthenticated"
          icon="far fa-magnifying-glass"
          class="mx-2"
          density="comfortable"
          @click="globalStore.mobileSearch = !globalStore.mobileSearch"
        >
        </v-btn>
      </template>
      <!-- /MOBILE -->
    </TheAppBar>
    <!-- /APP BAR -->

    <v-main class="bg-background-grey">
      <ContainerLayoutWrapper>
        <slot>
          <router-view />
        </slot>
        <!-- Overused subscription blocking overlay -->
        <OverlayOverusedSubscription />
        <!-- /Overused subscription blocking overlay -->
      </ContainerLayoutWrapper>
    </v-main>
  </v-layout>
</template>

<script lang="ts" setup>
import { ref, computed, watch, inject } from 'vue';
import debounce from 'lodash/debounce';
import MenuOrganizationSelection from '@/components/menu/MenuOrganizationSelection.vue';
import AppBarFavoritesMenu from '@/components/appbar/AppBarFavoritesMenu.vue';
import AppBarRecentMenu from '@/components/appbar/AppBarRecentMenu.vue';
import OverlayOverusedSubscription from '@/components/overlays/OverlayOverusedSubscription.vue';
import AppBarServiceMenu from '@/components/appbar/AppBarServiceMenu.vue';
import TheAppBar from '@/components/appbar/TheAppBar.vue';
import ContainerLayoutWrapper from '@/components/containers/ContainerLayoutWrapper.vue';

import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me';
import { useGlobalStore } from '@/stores/global/global';
import { useDeepBoxOverviewStore } from '@/stores/deepbox/overview';
import { useDeepAdminUsersCompanyAccountingStore } from '@/stores/deepadmin/users/users-company-accounting';
import { useRoute, useRouter } from 'vue-router';
import { DeviceKey } from '@/plugins/device-detector-js.ts';
import { isAuthenticated } from '@/composables/auth/use-keycloak.ts';
import type { UserMeOrganization } from '@/api/types/deepadmin/users/user.ts';
import { useDisplay } from 'vuetify';

const device = inject(DeviceKey);
const router = useRouter();
const route = useRoute();
const display = useDisplay();

const searchField = ref('');

const deepBoxOverviewStore = useDeepBoxOverviewStore();
const deepAdminUsersMeStore = useDeepAdminUsersMeStore();
const globalStore = useGlobalStore();
const deepAdminUsersCompanyAccountingStore =
  useDeepAdminUsersCompanyAccountingStore();

const selectedOrganizationId = computed(
  () => deepAdminUsersMeStore.selectedOrganizationId || ''
);

const isDashboardRoute = computed(() => route.name === 'organization');

watch(
  () => searchField.value,
  (newValue) => {
    if (newValue?.length > 0) {
      searchBoxesDebounced(newValue);
    } else {
      globalStore.setShowMobileSearchResult(false);
    }
  }
);

const searchBoxesDebounced = debounce(async (search: string) => {
  await deepBoxOverviewStore.fetchOverviewFull({
    companyId: selectedOrganizationId.value,
    query: search
  });
  globalStore.setShowMobileSearchResult(true);
}, 500);

async function onChangeOrganization(organization: UserMeOrganization) {
  if (selectedOrganizationId.value !== organization.group_id) {
    deepAdminUsersCompanyAccountingStore.$reset();
    deepAdminUsersMeStore.selectOrganization(organization);
    try {
      await deepAdminUsersCompanyAccountingStore.fetchCompanyAccounting(
        organization.group_id
      );
    } catch (e) {
      console.error(e);
    }
    router.push({
      name: 'organization',
      params: { organization: organization.group_id }
    });
  }
}

async function goBack() {
  searchField.value = '';
  globalStore.mobileSearch = false;
  globalStore.setShowMobileSearchResult(false);
  await deepBoxOverviewStore.fetchOverviewFull({
    companyId: selectedOrganizationId.value
  });
}
</script>
<style lang="scss" scoped>
.v-menu__content--fixed {
  border-radius: 12px;
}

.fav-recent-menu-btn {
  font-size: 14px;
  font-weight: 500;
  color: #212529;
}

.rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
</style>
