<template>
  <DeepAppDialog
    :title="$t('nodes.upload_card.title')"
    icon="far fa-upload"
    :max-width="500"
    data-test-id="uploads-dialog"
    v-bind="$attrs"
  >
    <template v-if="pollMap" #content>
      <CardExpandable v-if="pollMapHasFolders">
        <template #title>
          <div class="text-h6">
            {{ $t('hints.n_folders', Object.keys(pollMap.folders).length) }}
          </div>
          <v-spacer />
        </template>
        <template #content>
          <v-list
            density="compact"
            class="pr-2 overflow-y-auto"
            style="max-height: 500px"
          >
            <DevTreeView :json="JSON.stringify({ pollMap })" />
            <v-list-item
              v-for="folder in pollMap.folders"
              :key="folder.id"
              prepend-icon="far fa-folder-arrow-up"
              slim
              class="pa-0"
            >
              <template v-if="folder.data" #title>
                {{ folder.data.name }}
              </template>
              <v-list-item-subtitle v-if="folder.data" class="text-error">
                {{ getPollMapErrorMsgFormatted(folder.data.error) }}
              </v-list-item-subtitle>
              <template #append>
                <v-icon
                  v-if="
                    folder.status === 'processing' || folder.status === 'added'
                  "
                  v-tooltip="
                    $t('upload.status.' + folder.status) +
                    ` (${folder.progress} %)`
                  "
                >
                  <v-progress-circular width="2" :model-value="folder.progress">
                  </v-progress-circular>
                </v-icon>
                <v-icon
                  v-else
                  v-bind="getPollMapStateIcon(folder.status)"
                ></v-icon>
              </template>
            </v-list-item>
          </v-list>
        </template>
      </CardExpandable>
      <v-divider v-if="pollMapHasFolders && pollMapHasFiles" class="ma-2" />
      <CardExpandable v-if="pollMapHasFiles">
        <template #title>
          <div class="text-h6">
            {{ $t('hints.n_files', pollMap.files.length) }}
          </div>
          <v-spacer />
        </template>
        <template #content>
          <v-list
            density="compact"
            class="pr-2 overflow-y-auto"
            style="max-height: 500px"
          >
            <DevTreeView :json="JSON.stringify({ pollMap })" />
            <v-list-item
              v-for="file in pollMap.files"
              :key="file.id"
              class="pa-0"
            >
              <template #prepend>
                <v-avatar v-if="getFileIconNode(file)" size="20" tile>
                  <v-img
                    class="mx-1"
                    :src="`/svg/files/${getNodeIcon(getFileIconNode(file))}-20-px.svg`"
                    alt="node-icon"
                  />
                </v-avatar>
              </template>
              <template v-if="file.data" #title>
                <span v-if="file.data.name" data-test-id="filename">
                  {{ file.data.name }}
                </span>
                <span v-else-if="file.data.file">
                  {{ file.data.file.name }}
                </span>
              </template>
              <v-list-item-subtitle v-if="file.data" class="text-error">
                {{ getPollMapErrorMsgFormatted(file.data.error) }}
              </v-list-item-subtitle>

              <template #append>
                <v-icon
                  v-if="file.status === 'processing' || file.status === 'added'"
                  v-tooltip="
                    $t('upload.status.' + file.status) + ` (${file.progress} %)`
                  "
                >
                  <v-progress-circular
                    :indeterminate="
                      file.status === 'processing' && !file.progress
                    "
                    width="2"
                    :model-value="file.progress"
                  >
                  </v-progress-circular>
                </v-icon>
                <v-icon
                  v-else
                  data-test-id="upload-completed"
                  v-bind="getPollMapStateIcon(file.status)"
                />
              </template>
            </v-list-item>
          </v-list>
        </template>
      </CardExpandable>
    </template>
  </DeepAppDialog>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';

// components
import CardExpandable from '@/components/cards/CardExpandable.vue';
import DevTreeView from '@/components/dev/DevTreeView.vue';
import { DeepAppDialog } from '@deepcloud/deep-ui-lib';

// composables
import { useNodeUpload } from '@/components/node/use-node-upload.ts';

// utilities
import { getNodeIcon } from '@/utils/helpers/nodes.ts';

// types & constants
import {
  type PollMap,
  type PollMapNode
} from '@/stores/deepbox/deepboxes/upload.ts';

const props = defineProps({
  data: {
    type: Object as PropType<Record<number, PollMap>>,
    default: () => ({})
  }
});

const { getPollMapStateIcon, getPollMapErrorMsgFormatted } = useNodeUpload();

const pollMap = computed(() => Object.values(props.data)[0]);

const pollMapHasFolders = computed(
  () => pollMap.value.folders && Object.keys(pollMap.value.folders).length > 0
);

const pollMapHasFiles = computed(
  () => pollMap.value.files && pollMap.value.files.length > 0
);

function getFileIconNode(pollMapNode: PollMapNode) {
  if (!pollMapNode) return undefined;

  if (pollMapNode.status === 'completed') {
    return pollMapNode.data?.node;
  } else {
    return 'unknown';
  }
}
</script>
