<template>
  <DeepAppDialog
    v-model="model"
    title="DeepFlow"
    max-width="80%"
    height="90vh"
    :closable="closable"
    card-text-cls="pa-0 ma-0"
    show-fullscreen
    @close="onCancel"
  >
    <template #content>
      <v-container v-if="model" class="pa-0 ma-0 fill-height" fluid>
        <app-loader v-if="isLoading" />
        <iframe
          v-show="!isLoading"
          class="iframe"
          allow="clipboard-read; clipboard-write"
          name="deepFlowIframe"
          title="deep-flow-runtime"
          :src="iframeSrc"
          height="100%"
          width="100%"
          @load="onIFrameLoad"
        ></iframe>
      </v-container>
    </template>
  </DeepAppDialog>
</template>

<script setup lang="ts">
import { ref, onUnmounted } from 'vue';
import { DeepAppDialog } from '@deepcloud/deep-ui-lib';
import { useKeycloak } from '@/composables/auth/use-keycloak';
import { useI18n } from 'vue-i18n';

defineProps({
  iframeSrc: {
    type: String,
    default: ''
  },
  closable: {
    type: Boolean,
    default: false
  }
});

const model = defineModel({ type: Boolean, default: false });

const { token, tokenType, updateToken, addListener, removeListener } =
  useKeycloak();
const i18n = useI18n();

const isLoading = ref(false);

window.addEventListener('message', receiveMessage);

onUnmounted(() => {
  window.removeEventListener('message', receiveMessage);
  removeListener('onAuthRefreshSuccess', postMessageToIframe);
});

async function receiveMessage(event: MessageEvent) {
  if (event.data === 'ready') {
    await updateToken(-1);
    postMessageToIframe();

    addListener('onAuthRefreshSuccess', postMessageToIframe);
  }
  if (event.data === 'cancel') {
    close();
  }
}

function postMessageToIframe() {
  const iframe = window.frames['deepFlowIframe'];
  const data = {
    token: token.value,
    tokenType: tokenType.value,
    userLang: i18n.locale.value
  };
  iframe.postMessage(JSON.stringify(data), '*');
}

function onIFrameLoad() {
  isLoading.value = false;

  window.onmessage = function (event: MessageEvent) {
    if (event.data === 'cancel') {
      close();
    }
  };
}

function close() {
  model.value = false;
}

function onCancel() {
  close();
}
</script>
<style lang="scss" scoped>
.iframe {
  border: 0;
}
</style>
