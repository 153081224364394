<template>
  <v-menu
    v-if="
      organizationHasServicesPermission &&
      sortedServices.length > 0 &&
      currentOrganization &&
      $vuetify.display.smAndUp
    "
    v-model="servicesMenu"
    location="bottom right"
    :close-on-content-click="false"
  >
    <template #activator="{ props: ActivatorProps }">
      <v-btn v-bind="ActivatorProps" variant="text" class="pa-0">
        <v-icon>fas fa-puzzle</v-icon>
        <v-icon class="mx-1" :end="device.isTablet">
          {{ servicesMenu ? 'far fa-angle-up' : 'far fa-angle-down' }}
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item :title="$t('external_services.title')" />
      <v-divider />
      <v-list-item
        v-for="(service, index) in sortedServices"
        :key="index"
        :title="service.title"
        :subtitle="service.subtitle"
        :to="service.href"
        :prepend-avatar="service.img?.src"
      >
        <template #prepend>
          <DeepAvatar tile size="small" :logo="service.img?.src" />
        </template>
        <template #append>
          <v-icon>far fa-angle-right</v-icon>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { constants } from '@/constants/constants';
import { computed, inject, type PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useOrgBetaFeatures } from '@/composables/use-org-beta-features';
import type { Box, BoxInfo } from '@/api/types/deepbox/box.ts';
import { useBoxInfo } from '@/composables/use-box-info.ts';
import { useBox } from '@/composables/use-box.ts';
import { DeviceKey } from '@/plugins/device-detector-js.ts';
import helpers from '@/utils/helpers.ts';
import type { UserMeOrganization } from '@/api/types/deepadmin/users/user.ts';
import { DeepAvatar } from '@deepcloud/deep-ui-lib';

const props = defineProps({
  currentOrganization: {
    type: Object as PropType<UserMeOrganization>,
    default: undefined
  },
  box: {
    type: Object as PropType<Box | BoxInfo>,
    default: undefined
  }
});

const i18n = useI18n();
const device = inject(DeviceKey);

const { isAbaNinjaBoxEnabled } = useOrgBetaFeatures();

const servicesMenu = ref(false);

const parentBoxId = computed(() => props.box?.boxNodeId);

const { hasBoxRelationByBoxType } = useBoxInfo(
  computed(() => props.box as BoxInfo)
);

const { isBoxVariantAdvanced, isBoxVariantAdvancedPerUse } = useBox(
  computed(() => props.box as BoxInfo)
);

const services = computed(() => [
  {
    key: constants.STORE_SERVICE_HOOP,
    title: 'Hoop',
    subtitle: i18n.t('external_services.hoop.service_name'),
    img: {
      src: '/img/apps/hoop-icon.svg'
    },
    href: {
      name: 'organization-store-services-service',
      params: {
        organization: props.currentOrganization?.group_id,
        service: constants.STORE_SERVICE_HOOP
      },
      query: {
        parentBoxId: parentBoxId.value
      }
    },
    show: () => !helpers.isPersonalStructure(props.currentOrganization)
  },
  {
    key: constants.STORE_SERVICE_ABA_NINJA,
    title: 'AbaNinja',
    subtitle: i18n.t('external_services.aba_ninja.service_name'),
    img: {
      src: '/img/apps/aba-ninja-icon.svg'
    },
    href: {
      name: 'organization-store-services-service',
      params: {
        organization: props.currentOrganization?.group_id,
        service: constants.STORE_SERVICE_ABA_NINJA
      },
      query: {
        parentBoxId: parentBoxId.value
      }
    },
    show: () => {
      return (
        props.currentOrganization &&
        props.currentOrganization.group_id &&
        isAbaNinjaBoxEnabled(props.currentOrganization.group_id) &&
        props.box &&
        (isBoxVariantAdvanced.value || isBoxVariantAdvancedPerUse.value) &&
        !hasBoxRelationByBoxType('deep-ninja')
      );
    }
  }
]);

const sortedServices = computed(() =>
  services.value.filter((service) => service.show())
);

const organizationHasServicesPermission = computed(
  () => sortedServices.value.length > 0
);
</script>

<style scoped lang="scss">
.service-name {
  display: block;
  max-width: 165px !important;
}
</style>
