<template>
  <DeepAppDialog
    v-bind="$attrs"
    :title="$t('dialogs.support.title')"
    :max-width="500"
    @close="clearAndClose"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <!-- Content -->
    <template #content>
      <v-container>
        <v-form
          ref="formRef"
          v-model="isFormValid"
          :validate-on="isFormValid === undefined ? 'submit' : 'input'"
          @submit.prevent
        >
          <v-select
            v-model="form.type"
            :label="$t('dialogs.support.type_label')"
            :items="contactSupportItems"
            clearable
            :rules="[validationsRules.ruleRequired]"
            data-test-id="support-feedback-dropdown"
          ></v-select>

          <FormSupport v-if="form.type === 'support'" v-model="form" />

          <FormFeedback v-else-if="form.type === 'feedback'" v-model="form" />
        </v-form>
      </v-container>
    </template>
    <!-- /Content -->
    <!-- Actions -->
    <template #actions>
      <v-spacer />
      <VBtnOutlined name="btn-cancel" @click="clearAndClose">
        {{ $t('actions.cancel') }}
      </VBtnOutlined>
      <VBtnPrimary
        :class="{ 'btn-wrap-text': $vuetify.display.xs }"
        name="btn-send"
        :disabled="attachmentsSizeTooLarge || !form.type"
        :loading="isLoading"
        data-test-id="send-btn"
        @click="sendRequest"
      >
        {{ $t('buttons.send') }}
      </VBtnPrimary>
    </template>
    <!-- /Actions -->
  </DeepAppDialog>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';

// components
import { DeepAppDialog } from '@deepcloud/deep-ui-lib';
import FormFeedback from './FormFeedback.vue';
import FormSupport from './FormSupport.vue';

// types & constants
import type { ContactFeedback, ContactSupport } from './const.ts';
import { SUPPORT_TYPES } from '@/api/types/deepadmin/users/users-support.ts';
import type { VForm } from 'vuetify/components';

// utilities
import validationsRules from '@/utils/validations/rules';
import { deepAdminUsersSupportAPI } from '@/api/deepadmin/users/users-support.ts';
import { toast } from 'vue-sonner';
import { useI18n } from 'vue-i18n';
import { useKeycloak } from '@/composables/auth/use-keycloak.ts';

const emit = defineEmits(['update:modelValue']);

const i18n = useI18n();

const isLoading = ref(false);
const attachmentsSizeTooLarge = ref(false);

const DEFAULT_FORM = {
  contact: '',
  text: '',
  type: undefined
};

const form = ref<ContactFeedback | ContactSupport>({ ...DEFAULT_FORM });

const isFormValid = ref<boolean | undefined>(undefined);

const contactSupportItems = computed(() => [
  {
    id: SUPPORT_TYPES.SUPPORT,
    value: SUPPORT_TYPES.SUPPORT,
    title: i18n.t('dialogs.support.types.support.title')
  },
  {
    id: SUPPORT_TYPES.FEEDBACK,
    value: SUPPORT_TYPES.FEEDBACK,
    title: i18n.t('dialogs.support.types.feedback.title')
  }
]);

const formRef = ref();

async function sendRequest() {
  const { valid } = await formRef.value.validate();
  if (valid) {
    const type = form.value.type;
    isLoading.value = true;
    await deepAdminUsersSupportAPI.post(form.value);
    isLoading.value = false;
    clearAndClose();
    if (type === 'support') {
      toast.success(i18n.t('messages.success.support_requested'));
    } else if (type === 'feedback') {
      toast.success(i18n.t('messages.success.feedback_sent'));
    }
  } else {
    toast.error(i18n.t('error.validation.invalid'));
  }
}

function close() {
  emit('update:modelValue', false);
}

function clear() {
  form.value = {
    ...DEFAULT_FORM
  };
  isFormValid.value = undefined;
  formRef.value?.reset();
}

function clearAndClose() {
  clear();
  close();
}

const { user } = useKeycloak();
watch(
  () => form.value.type,
  (newValue) => {
    form.value = {
      ...DEFAULT_FORM,
      contact: user.value?.email,
      type: newValue
    };
  }
);
</script>
