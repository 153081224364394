<template>
  <DeepMenuUser
    v-model="menu"
    v-model:locale="locale"
    :items="menuItems"
    :user="user"
    :color="color"
    :is-mobile="device?.isMobile"
    :available-locales="availableLocales"
    @user:subtitle:dblclick="onUserEmailDoubleClick"
  />

  <!-- SUPPORT DIALOG -->
  <DialogSupport v-model="showDialogSupport" />
  <!-- /SUPPORT DIALOG -->
</template>

<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue';
import { DeepMenuUser } from '@deepcloud/deep-ui-lib';

// components
import DialogSupport from '@/components/dialogs/support/DialogSupport.vue';

// composables
import { useRoute } from 'vue-router';
import { useDeepaChatDrawer } from '@/components/deepa/use-deepa-chat-drawer';
import { useNotificationsNews } from '@/components/notifications/use-notifications-news';
import { useI18n } from 'vue-i18n';
import { useKeycloak } from '@/composables/auth/use-keycloak';
import { useAppLocale } from '@/composables/use-app-locale.ts';

// stores
import { useSettingsDevStore } from '@/stores/settings/settings-dev';

// utilities
import { DeviceKey } from '@/plugins/device-detector-js.ts';
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me.ts';
import { useKnowledgeCenter } from '@/composables/use-knowledge-center.ts';
import { useClipboard } from '@vueuse/core';
import { toast } from 'vue-sonner';

defineProps({
  color: {
    type: String,
    default: 'primary'
  }
});

const settingsDevStore = useSettingsDevStore();
const deepAdminUsersMeStore = useDeepAdminUsersMeStore();

const route = useRoute();
const device = inject(DeviceKey);

const { setAppLocale } = useAppLocale();
const { t, locale, availableLocales } = useI18n();

watch(
  () => locale.value,
  async (newValue) => {
    if (newValue) {
      await setAppLocale(newValue);
      menu.value = false;
    }
  }
);

const { user: userAuth, isAuthenticated, logout } = useKeycloak();

const menu = ref(false);

const user = computed(() => {
  if (isAuthenticated.value) {
    return {
      ...userAuth.value,
      displayName: userAuth.value?.display_name || userAuth.value?.name
    };
  }
  return null;
});

const getItemInstallAppPropHref = () => {
  if (device && device.isMobile) {
    if (device.isIos) {
      return import.meta.env.VITE_DEEPBOX_IOS_CLIENT_URL;
    }
    if (device.isAndroid) {
      return import.meta.env.VITE_DEEPBOX_ANDROID_CLIENT_URL;
    }
  }
  return null;
};
const getItemInstallAppPropTo = () => {
  if (device && device.isMobile) {
    if (!device.isIos && !device.isAndroid) {
      return { name: 'client-apps' };
    }
  }
  return { name: 'client-apps' };
};

const deepaChatDrawer = useDeepaChatDrawer();
const notificationsNews = useNotificationsNews();

const showDialogSupport = ref(false);

const deepAdminSettingsUrl = computed(() => {
  const deepAdminFrontendBaseUrl = `${
    import.meta.env.VITE_DEEPADMIN_FRONTEND_BASE_URL
  }organizations/${deepAdminUsersMeStore.selectedOrganizationId}/info`;
  if (!deepAdminFrontendBaseUrl) return '';
  const url = new URL(deepAdminFrontendBaseUrl);
  const hostOrigin = window.location.origin;
  url.searchParams.append('backUrl', `${hostOrigin}${route.fullPath}`);
  return url.toString();
});

const { login } = useKeycloak();

function onLogin() {
  const hostOrigin = window.location.origin;
  login({ redirectUri: `${hostOrigin}${route.fullPath}` });
}

const { linkDeepBox: linkKnowledgeCenter } = useKnowledgeCenter();

const menuItems = computed(() => {
  return [
    {
      key: 'deep_admin',
      title: t('actions.manage_your_account'),
      dataTestId: 'item-deep-admin',
      show: () => isAuthenticated.value && !device?.isMobile,
      href: deepAdminSettingsUrl.value,
      target: '_blank'
    },
    {
      key: 'install_app',
      title: t('actions.install_app'),
      dataTestId: 'item-install-app',
      href: getItemInstallAppPropHref(),
      target: getItemInstallAppPropHref() ? '_self' : null,
      to: getItemInstallAppPropTo(),
      show: () => isAuthenticated.value,
      action: () => {
        menu.value = false;
      }
    },
    {
      key: 'language',
      title: t('actions.language'),
      dataTestId: 'user-language'
    },
    {
      key: 'support',
      title: t('actions.support'),
      dataTestId: 'item-support',
      items: [
        {
          key: 'need_help',
          title: t('actions.contact_support'),
          dataTestId: 'item-contact-support',
          action: () => {
            showDialogSupport.value = true;
          }
        },
        {
          key: 'knowledge_center',
          title: t('actions.knowledge_center'),
          dataTestId: 'item-knowledge-center',
          href: linkKnowledgeCenter.value,
          target: '_blank'
        },
        {
          key: 'deepa_chat',
          title: 'DeepA Chat',
          dataTestId: 'item-deepa-chat',
          action: () => {
            deepaChatDrawer.showDrawer.value = true;
            menu.value = false;
          },
          show: () =>
            isAuthenticated.value &&
            settingsDevStore.canModeFeature('DEEP_A') &&
            !device?.isMobile,
          append: {
            flag: 'DEV',
            text: '⌘+H'
          }
        }
      ]
    },
    {
      show: () => isAuthenticated.value && settingsDevStore.devModeEnabled,
      divider: true,
      space: 'ma-2'
    },
    {
      key: 'dev-mode',
      title: `DEV mode: ${settingsDevStore.devModeEnabled ? 'enabled' : 'disabled'}`,
      class: settingsDevStore.devModeEnabled ? 'success--text' : 'error--text',
      prepend: {
        icon: {
          icon: 'far fa-bug',
          color: settingsDevStore.devModeEnabled ? 'success' : 'error'
        }
      },
      dataTestId: 'item-dev-mode',
      show: () => isAuthenticated.value && settingsDevStore.isDevModeAllowed,
      action: () => {
        menu.value = false;
        settingsDevStore.devShowSettings = !settingsDevStore.devShowSettings;
      },
      append: {
        flag: 'DEV'
      }
    },
    {
      key: 'dev-news',
      title: `What is new ?`,
      dataTestId: 'item-dev-news',
      prepend: {
        icon: {
          icon: 'far fa-sparkles',
          color: 'yellow'
        }
      },
      show: () =>
        isAuthenticated.value && settingsDevStore.canModeFeature('NEWS_LIST'),
      action: () => {
        menu.value = false;
        notificationsNews.showDrawer.value = true;
      },
      append: {
        flag: 'DEV'
      }
    },
    {
      divider: true,
      space: 'ma-2'
    },
    {
      key: 'logout',
      title: t('actions.logout'),
      dataTestId: 'item-logout',
      show: () => isAuthenticated.value,
      action: () => logout({})
    },
    {
      key: 'login',
      title: t('actions.login'),
      dataTestId: 'item-login',
      show: () => !isAuthenticated.value,
      action: () => onLogin()
    }
  ];
});

async function onUserEmailDoubleClick() {
  const { copy, copied, isSupported } = useClipboard({
    source: user.value?.email
  });
  if (isSupported) {
    try {
      await copy(user.value?.email);
      if (copied) {
        toast.success('E-Mail copied successfully!');
      }
    } catch (e) {
      console.log(e);
      toast.error('Could not copy the e-mail');
    }
  }
}
</script>
