import { constants } from '@/constants';
import { useLoader } from '@deepcloud/deep-ui-lib';
import { toast } from 'vue-sonner';
import { useI18n } from 'vue-i18n';
import type { FileTree } from '@/types/file-tree.ts';
import type { Node } from '@/api/types/deepbox/node';
import type { SearchItem } from '@/api/types/deepbox/search.ts';
import { useNodeMimeType } from '@/composables/use-node-mime-type.ts';
import { useNodePolicy } from '@/composables/use-node-policy.ts';
import { useDragDropStore } from '@/stores/drag-drop.ts';
import type { PathSegment } from '@/api/types/deepbox/path.ts';
import { computed, ref } from 'vue';
import { useNode } from '@/composables/use-node.ts';
import { useRoute } from 'vue-router';
import { useUploader } from '@/composables/use-uploader.ts';
import helpers from '@/utils/helpers.ts';
import i18nGlobal from '@/plugins/i18n';
import { useFilesTree } from '@/composables/use-files-tree.ts';
import { useNodeMove } from '@/composables/use-node-move.ts';
import { checkNodesPolicy } from '@/utils/helpers/externals.ts';

const TREE_ITEM_HOVER_CLASS = 'deep-item-hover';
const DRAG_DROP_GHOST_ID = 'deep-drag-ghost';

function removeDeepDragGhostElIfExists() {
  const ghostEl = document.getElementById(DRAG_DROP_GHOST_ID);
  if (ghostEl) {
    document.body.removeChild(ghostEl);
  }
}

function generateGhostWarning() {
  // create ghost warning msg wrapper
  const ghostWarning = document.createElement('div');
  ghostWarning.classList.add('ghost-warning');
  ghostWarning.style.display = 'none';
  // create ghost warning msg
  const ghostWarningIcon = document.createElement('div');
  ghostWarningIcon.classList.add('ghost-warning-icon');

  const warningIcon = document.createElement('i');
  warningIcon.style.fontSize = '18px';
  warningIcon.style.height = '18px';
  warningIcon.style.width = '18px';
  warningIcon.className = 'fas fa-triangle-exclamation v-icon text-warning';
  ghostWarningIcon.appendChild(warningIcon);

  ghostWarning.appendChild(ghostWarningIcon);
  // create ghost warning msg
  const ghostWarningText = document.createElement('div');
  ghostWarningText.classList.add('ghost-warning-text');
  ghostWarning.appendChild(ghostWarningText);

  return ghostWarning;
}

function generateGhostChildImg(e: DragEvent) {
  // create ghost child icon
  const ghostChildIcon = document.createElement('div');
  ghostChildIcon.classList.add('ghost-child-icon');
  // check if target as img
  let eTargetImgSrc;
  e.target.childNodes.forEach((el) => {
    if (el.nodeName === 'IMG') {
      if (el.src) {
        eTargetImgSrc = el.src;
      }
    }
  });

  if (eTargetImgSrc) {
    // add e target custom img
    const childImg = document.createElement('img');
    childImg.src = eTargetImgSrc;
    ghostChildIcon.appendChild(childImg);
  } else {
    return undefined;
  }

  return ghostChildIcon;
}

function generateGhostEl() {
  const ghost = document.createElement('div');
  ghost.id = DRAG_DROP_GHOST_ID;
  ghost.style.position = 'absolute';
  ghost.style.top = '-150px';
  ghost.style.right = '100px';
  ghost.classList.add(DRAG_DROP_GHOST_ID);

  return ghost;
}

function generateGhostChild() {
  const ghostChild = document.createElement('div');
  ghostChild.classList.add('ghost-child');

  return ghostChild;
}

function generateGhostChildText() {
  const ghostChildText = document.createElement('div');
  ghostChildText.classList.add('ghost-child-text');

  return ghostChildText;
}

function generateGhostChildIcon(icon: string) {
  const ghostChildIcon = document.createElement('div');
  ghostChildIcon.classList.add('ghost-child-icon');

  const childIcon = document.createElement('i');
  childIcon.style.fontSize = '18px';
  childIcon.style.height = '18px';
  childIcon.style.width = '18px';
  childIcon.className = `${icon} v-icon text-primary`;
  ghostChildIcon.appendChild(childIcon);

  return ghostChildIcon;
}

function generateGhostChildFolder(text: string) {
  const ghostChildFolders = generateGhostChild();
  // add file icon
  const ghostChildFoldersIcon = generateGhostChildIcon('far fa-folder');
  ghostChildFolders.appendChild(ghostChildFoldersIcon);
  // add text
  const ghostChildFoldersText = generateGhostChildText();
  ghostChildFoldersText.innerHTML = text;
  ghostChildFolders.appendChild(ghostChildFoldersText);

  return ghostChildFolders;
}

function generateGhostChildFolders(count: number) {
  return generateGhostChildFolder(
    i18nGlobal.global.t('hints.n_folders', count)
  );
}

function generateGhostChildFiles(count: number) {
  const ghostChildFiles = generateGhostChild();
  // add folder icon
  const ghostChildFilesIcon = generateGhostChildIcon('far fa-file');
  ghostChildFiles.appendChild(ghostChildFilesIcon);
  // add text
  const ghostChildFilesText = generateGhostChildText();
  ghostChildFilesText.innerHTML = i18nGlobal.global.t('hints.n_files', count);
  ghostChildFiles.appendChild(ghostChildFilesText);

  return ghostChildFiles;
}

function generateGhost(
  e: DragEvent,
  nodes: Node[] | SearchItem[] | PathSegment[]
) {
  if (nodes.length === 0) return;

  const ghost = generateGhostEl();

  // create ghost badge if > 1 node
  if (nodes.length > 1) {
    const ghostBadge = document.createElement('div');
    ghostBadge.classList.add('ghost-badge');
    ghostBadge.innerHTML = nodes.length;
    ghost.appendChild(ghostBadge);
  }

  // handle case of only 1 node
  if (nodes.length === 1) {
    const elName = e.target?.innerText;
    if (!elName) return;
    if (nodes[0].type === 'folder') {
      const ghostChildFolders = generateGhostChildFolder(elName);
      ghost.appendChild(ghostChildFolders);
    } else {
      // create ghost child
      const ghostChild = generateGhostChild();

      const ghostChildIcon = generateGhostChildImg(e);
      if (ghostChildIcon) {
        ghostChild.appendChild(ghostChildIcon);
      }

      // add ghost child text
      const ghostChildText = generateGhostChildText();
      ghostChildText.innerHTML = elName;
      ghostChild.appendChild(ghostChildText);
      ghost.appendChild(ghostChild);
    }
  } else {
    // handle multiple nodes
    const nodeFolders = nodes.filter((n) => n.type === 'folder');
    const nodeFiles = nodes.filter((n) => n.type !== 'folder');

    // has folders and has files
    if (nodeFiles.length > 0 && nodeFolders.length > 0) {
      const ghostChildFiles = generateGhostChildFiles(nodeFiles.length);
      ghost.appendChild(ghostChildFiles);

      const ghostChildFolders = generateGhostChildFolders(nodeFolders.length);
      ghost.appendChild(ghostChildFolders);
    } else if (nodeFiles.length > 1 && nodeFolders.length === 0) {
      // has only files
      const ghostChildFiles = generateGhostChildFiles(nodeFiles.length);
      ghost.appendChild(ghostChildFiles);
    } else if (nodeFiles.length === 0 && nodeFolders.length > 1) {
      // has only folders
      const ghostChildFolders = generateGhostChildFolders(nodeFolders.length);
      ghost.appendChild(ghostChildFolders);
    }
  }

  const ghostWarning = generateGhostWarning();
  if (ghostWarning) {
    ghost.appendChild(ghostWarning);
  }

  return ghost;
}

export function useDragDrop() {
  const deepLoader = useLoader();
  const i18n = useI18n();
  const route = useRoute();

  const dragDropStore = useDragDropStore();

  const { getCurrentRootNodeFromRoute } = useNode();
  const currentNode = computed(() => getCurrentRootNodeFromRoute(route));

  const currentTargetNode = computed(() => {
    if (dragDropStore.targetNode) {
      return dragDropStore.targetNode;
    }
    return currentNode.value;
  });

  const { isNodeMimeTypeTrash, isNodeMimeTypeInbox } = useNodeMimeType();
  const { canAddChildren, canDelete } = useNodePolicy();

  const uploader = useUploader();

  async function onDropNodeUpload(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();

    deepLoader.show(i18n.t('loader.files.are_being_indexed'));
    const entries = await helpers.getAllFileEntries(e);
    deepLoader.hide();
    // entries = false if a file has a size greater than constants.UPLOAD_MAX_SIZE
    if (!entries) {
      toast.error(i18n.t('messages.error.file_size'));
      return;
    }
    await uploader.uploadEntries(entries, dragDropStore.targetNode);
  }

  function getDataTransferSourceNodes(eDataTransfer: DataTransfer) {
    const sourceNodesDataTransfer = eDataTransfer.getData('sourceNodes');

    return sourceNodesDataTransfer ? JSON.parse(sourceNodesDataTransfer) : [];
  }

  function getSourceNodesFolders(sourceNodes: Node[]) {
    return sourceNodes.filter((n) => n.type === constants.NODE_TYPE_FOLDER);
  }

  const { moveNodes, refreshCurrentNodesOnMove } = useNodeMove();

  async function onDropNodeMove(
    eDataTransfer: DataTransfer,
    target: Node | FileTree | SearchItem
  ) {
    if (!target) {
      console.error('target not found');
      return;
    }

    if (!canAddChildrenOrIsTrash(target)) {
      toast.error(
        i18n.t('drag_drop.msg.error.missing_permissions_to_add_children')
      );
      console.log('Target do not have permission to add files');
      return;
    }

    // The section where the nodes were from (files, inbox or trash)
    const section = eDataTransfer.getData('section');
    let sourceNodes: Node[] = getDataTransferSourceNodes(eDataTransfer);

    // remove nodes with type folder
    const sourceNodesFolders = getSourceNodesFolders(sourceNodes);

    if (isNodeMimeTypeInbox(target) && sourceNodesFolders.length > 0) {
      toast.error(
        i18n.t('error.inbox.folders_are_not_allowed', sourceNodesFolders.length)
      );

      // remove nodes with type folder
      sourceNodes = sourceNodes.filter(
        (n) => n.type !== constants.NODE_TYPE_FOLDER
      );

      if (sourceNodes.length === 0) {
        console.log('=> 0 source nodes found');
        return Promise.resolve();
      }
    }

    const sourceNodeIds = sourceNodes.map((n: Node) => n.nodeId);

    const parentNodeId = eDataTransfer.getData('parentNodeId');
    eDataTransfer.dropEffect = 'move';
    eDataTransfer.effectAllowed = 'move';
    dragDropStore.nodesBeingDragged = false;

    // if the target is one of the node to be moved
    if (sourceNodeIds.includes(target.nodeId)) {
      console.log('=> target is one the node to be moved');
      toast.error(
        i18n.t('drag_drop.msg.error.target_is_one_of_the_nodes_to_be_moved')
      );
      return Promise.resolve();
    }

    // return if we're trying to move nodes to their same parent
    if (parentNodeId === target.nodeId) {
      console.log('=> Is not possible to move a node to their parent');
      toast.error(
        i18n.t('drag_drop.msg.error.move_to_same_parent', sourceNodeIds.length)
      );
      return Promise.resolve();
    }

    //  if target is trash, we should check if the source node as the policy "canDelete"
    if (isNodeMimeTypeTrash(target)) {
      const sourceNodesWithMissingPolicyCanDelete = sourceNodes.filter(
        (n) => !canDelete(n)
      );
      if (sourceNodesWithMissingPolicyCanDelete.length > 0) {
        toast.error(
          i18n.t(
            'drag_drop.msg.error.missing_permissions_to_delete_some_nodes',
            sourceNodesWithMissingPolicyCanDelete
          )
        );
      }

      // remove nodes that do not have the policy 'canDelete'
      sourceNodes = sourceNodes.filter((n) => canDelete(n));

      if (sourceNodes.length === 0) {
        console.log('=> 0 source nodes found');
        return Promise.resolve();
      }
    }

    // TODO: check if we're trying to move nodes to some of their children

    console.log('onDropNode(). All good. We can move the nodes');
    console.log('sourceNodeIds', sourceNodeIds);
    console.log('targetNode', target);
    console.log('section', section);
    dragDropStore.targetNode = target;

    try {
      deepLoader.show();
      const successMovedNodesCount = await moveNodes({
        section,
        nodeIds: sourceNodeIds,
        targetParentNode: target
      });
      if (successMovedNodesCount && successMovedNodesCount > 0) {
        let targetName;
        if (
          [
            constants.FILETREE_QUEUE,
            constants.FILETREE_FILES,
            constants.FILETREE_TRASH,
            constants.FILETREE_DEADLINES,
            constants.FILETREE_INBOX,
            constants.FILETREE_SHARES
          ].includes(target.name)
        ) {
          targetName = i18n.t(`box_details.navigation.${target.name}`);
        } else {
          targetName = target.displayName || target.name;
        }
        toast.success(
          i18n.t(
            'drag_drop.msg.success.node_moved_to_target',
            {
              targetName
            },
            sourceNodeIds.length
          )
        );
        await doRefreshFilesTreeOnMove(parentNodeId, target.nodeId, target);
        await refreshCurrentNodesOnMove(target.nodeId, sourceNodeIds);
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    } finally {
      deepLoader.hide();
    }
  }

  async function onDropNode(
    e: DragEvent,
    target?: Node | FileTree | SearchItem
  ) {
    const eDataTransfer = e.dataTransfer;
    if (
      !e ||
      (!eDataTransfer &&
        !dragDropStore.nodesBeingDragged &&
        !dragDropStore.nodesBeingDragUploaded)
    ) {
      return Promise.resolve();
    }

    if (dragDropStore.nodesBeingDragged) {
      await onDropNodeMove(eDataTransfer, target);
    } else {
      if (!canAddChildren(currentTargetNode.value)) return;
      await onDropNodeUpload(e);
    }

    // reset store
    dragDropStore.$reset();
  }

  function onDragStartNodes(
    e: DragEvent,
    nodes: Node[] | SearchItem[] | PathSegment[],
    parentNodeId?: string,
    section?: string
  ) {
    // check if all selected items can be moved
    if (!checkNodesPolicy(nodes, 'canMoveWithinBox')) {
      return;
    }

    // Set the relative flag to true (this flag pres the drag upload listeners to be triggered)
    dragDropStore.nodesBeingDragged = true;

    if (!nodes || nodes.length === 0) {
      return;
    }

    // Build the ghost element to be attached as drag image
    const ghostEl = generateGhost(e, nodes);
    if (ghostEl) {
      document.body.appendChild(ghostEl);
    } else {
      return;
    }

    // Set the ghost as drag image.
    e.dataTransfer && (e.dataTransfer.effectAllowed = 'move');

    if (e.dataTransfer) {
      e.dataTransfer.setDragImage(ghostEl, 0, 0);
      e.dataTransfer.setData('sourceNodes', JSON.stringify(nodes));
      e.dataTransfer.setData(
        'sourceNodeIds',
        nodes.map((n) => n.nodeId)
      );
      if (parentNodeId) {
        e.dataTransfer.setData('parentNodeId', parentNodeId);
      }
      if (section) {
        e.dataTransfer.setData('section', section);
      }
    }
    // remove the created ghost element
    setTimeout(() => {
      if (ghostEl?.parentNode) {
        ghostEl.parentNode.removeChild(ghostEl);
      }
    }, 1000);
  }

  const isNodeOver = ref(false);
  const isNodeOverLocked = ref(false);

  function canAddChildrenOrIsTrash(node: Node | FileTree | PathSegment) {
    if (isNodeMimeTypeTrash(node)) {
      return true;
    }

    return canAddChildren(node);
  }

  function onDragOver(
    e: DragEvent,
    node: Node | FileTree | PathSegment,
    overEl: Element
  ) {
    /* Improvements/Todos
     * - if target is "inbox" and the source is type 'folder' -> prevent drag over
     *
     * */
    isNodeOver.value = true;
    if (isNodeOverLocked.value) {
      return;
    }

    isNodeOverLocked.value = false;

    if (!node) return;

    if (overEl) {
      // set `nodesBeingDragUploaded` to true, if not `nodesBeingDragged`
      // which means that we are on the drag upload
      if (!dragDropStore.nodesBeingDragged) {
        dragDropStore.nodesBeingDragUploaded = true;
      }

      // avoid to over the el if is upload and node do not have the policy 'canAddChildren'
      if (
        !canAddChildren(node) &&
        dragDropStore.nodesBeingDragUploaded &&
        !dragDropStore.nodesBeingDragged
      ) {
        e.stopPropagation();
        return;
      }
      // avoid to over the el if is 'move' and node do not have the policy 'canAddChildren' or is 'trash'
      if (dragDropStore.nodesBeingDragged && !canAddChildrenOrIsTrash(node)) {
        e.stopPropagation();
        return;
      }

      // drop over allowed
      e.preventDefault();
      dragDropStore.targetNode = node;
      overEl.classList.add(TREE_ITEM_HOVER_CLASS);
    }
  }

  function onDragLeave(
    e: DragEvent,
    node: Node | FileTree | PathSegment,
    overEl: Element
  ) {
    isNodeOver.value = true;
    if (isNodeOverLocked.value) {
      return;
    }

    if (!node) return;

    if (canAddChildrenOrIsTrash(node) && overEl) {
      if (
        dragDropStore.nodesBeingDragUploaded ||
        dragDropStore.nodesBeingDragged
      ) {
        dragDropStore.targetNode = undefined;
        dragDropStore.nodesBeingDragUploaded = false;
      }
      overEl.classList.remove(TREE_ITEM_HOVER_CLASS);
    }
  }

  function onDragEnd() {
    removeDeepDragGhostElIfExists();
    setTimeout(() => {
      dragDropStore.nodesBeingDragged = false;
    }, 300);
  }

  const { refreshFilesTreeOnMove } = useFilesTree();

  async function doRefreshFilesTreeOnMove(
    parentNodeId: string,
    targetNodeId: string,
    target: Node
  ) {
    try {
      deepLoader.show();
      await refreshFilesTreeOnMove(parentNodeId, targetNodeId, target);
    } finally {
      deepLoader.hide();
    }
  }

  return {
    TREE_ITEM_HOVER_CLASS,
    DRAG_DROP_GHOST_ID,
    onDropNode,
    onDropNodeUpload,
    onDragEnd,
    onDragStartNodes,
    currentTargetNode,

    onDragOver,
    onDragLeave,
    isNodeOver,
    isNodeOverLocked
  };
}
